
import React from 'react'
import {About, Services, Header, Work, Skills, Testimonial, Footer} from './container';
import {Navbar} from './components'
import './App.scss'
const App = () => {
  return (
    <div className="app">
      <Navbar />
      <Header />
      <About/>
      <Services />
      <Work/>
      <Skills/>
      <Footer/>
    </div>
  );
}

export default App