import React, { useState, useEffect } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';

import { images } from '../../constants';
import './Navbar.scss';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.querySelector('#about');
      const sectionTop = aboutSection.getBoundingClientRect().top;
      const sectionHeight = aboutSection.getBoundingClientRect().height;
      const scrollPosition = window.scrollY;
      const triggerPosition = sectionTop + sectionHeight * 0.5; // 80% of section height
  
      setIsScrolled(scrollPosition >= triggerPosition);
    };
  
    window.addEventListener('scroll', handleScroll);
  
    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`app__navbar ${isScrolled ? 'scrolled' : ''}`}>
      <div className="app__navbar-logo">
      <a href="#home"><img src={images.wjhLogo} alt="logo" /></a>
      </div>
      <ul className="app__navbar-links">
        {['home', 'about', 'services', 'work', 'skills', 'contact'].map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
      </ul>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {['home','about', 'services', 'work', 'skills', 'contact'].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
