import React from "react";
import "./Banner.scss";
import BannerImg from "../../../assets/pfp.jpg";
import resume from "../../../assets/William-Harries-CV.pdf";

const Banner = () => {
    const handleResumeClick = (event) => {
        event.preventDefault(); // Prevent the default behavior of the button
        window.open(resume); // Download the file
      };
  return (
    <div className="hero-banner">
      <div className="overlay"></div>
      <div className="content">
        <div className="text-content">
          <h1>Crafting Exceptional Web Experiences</h1>
          <p>
            Explore my portfolio of custom web designs and discover how I can
            help you take your online presence to the next level.
          </p>
          <div className="ctas">
            <div
              className="banner-cta"
              onClick={() => (window.location.href = "#work")}
            >
              See My Work
            </div>
            <div className="banner-cta v2" onClick={handleResumeClick}>
              Check Out My Resumé
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
