import React, {useState, useEffect} from 'react';
import './About.scss'
import {motion} from 'framer-motion'
import {images} from '../../constants'
import { urlFor, client } from '../../client';
import { AppWrap, MotionWrap } from '../../wrapper';

const About = () => {
  const [abouts, setAbouts] = useState([]);
  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
  }, []);
  const scaleVariants = {
    whileInView: {
      scale: [0, 1],
      opacity: [0, 1],
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
  };

  return (
   <>

<div className="app__header app__flex">
      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles-left"
      >
        {[images.html, images.javascript, images.css].map((circle, index) => (
          <div className="circle-cmp app__flex" key={`circle-${index}`}>
            <img src={circle} alt="profile_bg" />
          </div>
        ))}
      </motion.div>

      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles-center"
      >
        {[images.pfp].map((circle, index) => (
          <div className="circle-cmp app__flex" key={`circle-${index}`}>
            <img src={circle} alt="profile_bg" />
          </div>
        ))}
      </motion.div>

      

      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[images.node, images.react, images.sass].map((circle, index) => (
          <div className="circle-cmp app__flex" key={`circle-${index}`}>
            <img src={circle} alt="profile_bg" />
          </div>
        ))}
      </motion.div>
      <h2 className="head-text">
      Hi, I'm  
      <span>  Will</span> <br></br>
      A Freelance Web Developer<br></br>
    </h2>
    </div>

    {/* <h4 className="head-text-sm">
      Finding creative and innovative solutions for the web <br></br>
    </h4> */}
    
    
   </>
  )
}



export default AppWrap(
  MotionWrap(About, 'app__works'),
  'about',
  'app__primarybg',
);
