import React from 'react';
import { motion, animate, useMotionValue, useTransform } from 'framer-motion';
import { FaArrowDown } from 'react-icons/fa';
import { images } from '../../constants';
import './Header.scss';
import Banner from './Banner/Banner'
const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const scrollToAbout = () => {
  window.scrollTo({
    top: document.querySelector("#about").offsetTop,
    behavior: "smooth"
  });
}

const Header = () => (
  <div id="home">
    
    <Banner></Banner>
    
    <motion.button className="arrow-button" onClick={scrollToAbout}>
      <FaArrowDown className="arrow-icon" />
    </motion.button>
  </div>
);

export default Header;
